.light {
  --text-color: var(--black);
  --bg-color: var(--white);
}

.neutral {
  --text-color: var(--black);
  --bg-color: var(--beige);
}

.dark {
  --text-color: var(--white);
  --bg-color: var(--taupe);
}

.wrapper {
  background: var(--bg-color);
}

.container {
  --padding-container: var(--spacing-80);
  display: grid;
  grid-column: full-start / full-end !important;
  grid-template-columns: var(--grid-template-desktop);
}

.map {
  grid-column: full-start / 8;
  height: 100%;
}
.map > img {
  width: 100%;
  height: auto;
  object-fit: contain;
  margin: auto auto;
}

.residencesWrapper {
  display: grid;
  grid-template-columns: [ main-start] repeat(6, minmax(0, var(--size-row))) [ main-end] 1fr;
  grid-column: 8 / full-end;
}

.residencesContainer {
  background: var(--bg-color);
  color: var(--text-color);
  padding: 0 var(--spacing-80) 0 var(--spacing-40);
  display: flex;
  flex-flow: column nowrap;
  grid-column: main-start / main-end;
  gap: var(--spacing-24);
}

.residences {
  display: flex;
  flex-flow: column nowrap;
  gap: var(--spacing-24);
}

/* .residences > * {
  max-height: 250px;
} */

.link {
  display: flex;
  align-items: center;
  align-self: flex-start;
  font: var(--font-h3-mobile-default);
  font-weight: 900;
  color: var(--text-color);
  cursor: pointer;
  padding-bottom: var(--spacing-40);
  transition: color 0.3s ease;
}

.link svg {
  margin-top: 2px;
}

.link:hover {
  color: var(--black);
}

@media (max-width: 1200px) {
  .container {
    --padding-container: var(--spacing-40);
    padding: var(--padding-container) 0;
    gap: var(--spacing-40);
  }

  .map {
    grid-column: full-start / full-end;
  }

  .residencesWrapper {
    grid-column: full-start / full-end;
    grid-template-columns: var(--grid-template-mobile);
  }

  .residencesContainer {
    padding: 0;
    gap: 0;
  }

  .link {
    margin-right: var(--spacing-80);
    padding: var(--spacing-24);
  }

  .residences > * {
    max-height: 100%;
  }
}

@media (max-width: 1024px) {
  .wrapper {
    padding-bottom: 0;
  }

  .container {
    --padding-container: var(--spacing-24) 0;
    padding: var(--padding-container);
    gap: var(--spacing-24);
  }

  .link {
    margin-right: var(--spacing-16);
    padding-right: 0;
    font: var(--font-h4-mobile-default);
    font-weight: 900;
  }
}
