.dark{
  --card-text-color: var(--black);
  --card-bg-color: var(--white);
}

.light{
  --card-text-color: var(--black);
  --card-bg-color: var(--white);
}

.neutral{
  --card-text-color: var(--black);
  --card-bg-color: var(--beige);
}

.container {
  position: relative;
  display: flex;
  flex-flow: column wrap;
  border: 1px solid var(--gray);
  border-radius: var(--radius-m);
  transition: border 0.3s ease-in-out;
  height: 100%;
}

.horizontal.container {
  flex-flow: row wrap;
  width: 100%;
  border-color: var(--sauge);
}

.container:hover{
  border: 1px solid var(--taupe);
}

.image{
  position: relative;
  height: 400px;
}
.image img{
  border-radius: var(--radius-m) var(--radius-m) 0 0;
}

.horizontal .image {
  height: 100%;
  min-height: 208px;
  min-width: 200px;
  width: 25%;
}

.horizontal .image img {
  border-radius: var(--radius-m) 0 0 var(--radius-m);
}

.info{
  display: flex;
  flex-flow: column wrap;
  color: var(--card-text-color);
  padding: var(--spacing-40);
  gap: var(--spacing-16);
  flex: 1;
  justify-content: space-between;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}
.dark .info{
  color: var(--card-text-color);
  background: var(--card-bg-color);
}
.horizontal .info {
  padding: var(--spacing-24);
  justify-content: space-between;
}

.horizontal .infoButton{
  display: flex;
  align-items: flex-start;
  padding: var(--spacing-24) var(--spacing-24) var(--spacing-24) 0;
}
.horizontal .infoButton > *{
  display: flex;
  padding: 0;
  height: 50px;
  width: 50px;
  align-items: center;
  justify-content: center;
}

/* .container:hover .info{
  color: var(--beige);
  background: var(--sauge);
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
  border-radius: 0 0 var(--radius-m) var(--radius-m);
} */

.content{
  display: flex;
  flex-flow: column wrap;
  gap: var(--spacing-6);
  justify-content: flex-start;
}
.city{
  font: var(--font-h4-desktop-default);
  font-weight: 800;
}

.address{
  font: var(--font-p1-desktop-default);
}

.price {
  font: var(--font-h4-desktop-default);
  font-weight: 800;
}

.tag {
  position: absolute;
  top: var(--spacing-8);
  right: var(--spacing-8);
  display: flex;
  flex-flow: column nowrap;
  gap: var(--spacing-8);
  align-items: flex-start;
}

.tag > * {
  width: fit-content;
}

.search.container{
  border-radius: var(--radius-m);
}
.search .info{
  padding: var(--spacing-16);
}
.search.container .image{
  height: 220px;
}
.search.container img{
  border-radius:  var(--radius-m) var(--radius-m) 0 0;
}

.search .content{
  gap: var(--spacing-12);
}

.search .tag > div{
  background: var(--sauge);
  font: var(--font-p2-mobile-default);
  padding: var(--spacing-4) var(--spacing-8);
}

.horizontal .tag {
  top: var(--spacing-8);
  left: var(--spacing-8);
  align-items: flex-start;
}

@media (max-width: 1024px){
  .city{
    font: var(--font-h4-mobile-default);
    font-weight: 800
  }
  .address{
    font: var(--font-p1-mobile-default);
  }
  .price{
    font: var(--font-h4-mobile-default);
    font-weight: 800;
  }
  .image{
    height: 240px;
  }

  .content{
    padding: 0;
  }
}

@media (max-width: 768px){
  .search .tag > div{
    font: var(--font-p2-mobile-default);
    padding: var(--spacing-8) var(--spacing-12);
  }
}

@media (max-width: 480px){
  .info{
    gap: var(--spacing-40);
    padding: var(--spacing-24) var(--spacing-16) var(--spacing-40) var(--spacing-16) !important;
  }
}